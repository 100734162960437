








































































































































































































































import {Component, Prop} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import AdminShippingOrder from "@/store/entities/ordercontext/adminshippingorder";
import KeyWordLookUpRequest from "@/store/entities/key-word-look-up-request";
import CompanyKeyWordLookUpRequest from "@/store/entities/ordercontext/company-keyword-lookup-request";
import Company from "@/store/entities/companycontext/company";
import CreateCompany from "@/views/setting/companycontext/company/create-company.vue";
import OrderDocument from "@/views/setting/OrderContext/Order/order-document.vue";

@Component({
  components: {CreateCompany, OrderDocument}
})
export default class OrderAdminForm extends AbpBase {
  @Prop({type: Boolean, default: false}) value: boolean;

  toEdit: boolean;
  order: AdminShippingOrder = new AdminShippingOrder();

  private documents: [];
  private createCompanyModalShow: boolean = false;
  private isSaveButtonDisabled: boolean = false;
  private lastAddedCompany: string = '';
  private statuses: any;
  private clients: any;
  private shippingMethods: any;
  private incoterms: any;
  private originLocations: any;
  private destinationLocations: any;
  private suppliers: any;
  private consignees: any;
  private loadingAddresses: any;
  private unloadingAddresses: any;
  private originAgents: any;
  private destinationAgents: any;
  private toShowCustomSupplier: boolean;
  private toShowCustomConsignee: boolean;

  putCreatedValue(val) {
    this.order[this.lastAddedCompany] = val;
  }


  private isLoading: boolean = false;

  data() {
    return {
      clients: [],
      suppliers: [],
      statuses: [],
      consignees: [],
      loadingAddresses: [],
      unloadingAddresses: [],
      shippingMethods: [],
      incoterms: [],
      destinationLocations: [],
      originLocations: [],
      originAgents: [],
      destinationAgents: [],
      documents: []
    }
  };

  async createCompany(val, category, property) {
    this.order[property] = null;
    this.lastAddedCompany = property;
    let company = new Company();
    company.name = val;
    if (category) {
      let cat = await this.$store.dispatch('category/byCode', category);
      company.categories = new Array<number>();
      company.categories.push(cat.id);
    }
    this.$store.commit('company/edit', company);
    this.createCompanyModalShow = true;
  }


  async loadClients(query) {
    if (query !== null && query.length >= 1) {
      this.isLoading = true;
      this.clients = await this.$store.dispatch('company/keyWordLookUp', new KeyWordLookUpRequest(query, this.order.clientId));
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.clients = [];
    }
  }

  async loadSuppliers(query) {
    if (query !== null && query.length >= 1) {
      this.isLoading = true;
      this.suppliers = await this.$store.dispatch('company/keyWordLookUp', new CompanyKeyWordLookUpRequest(query, this.order.supplierId, '03'));
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.suppliers = [];
    }
  }

  async loadOriginAgents(query) {
    if (query !== null && query.length >= 1) {
      this.isLoading = true;
      this.originAgents = await this.$store.dispatch('company/keyWordLookUp', new CompanyKeyWordLookUpRequest(query, this.order.originAgentId, '01'));
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.originAgents = [];
    }
  }

  async loadDestinationAgents(query) {
    if (query !== null && query.length >= 1) {
      this.isLoading = true;
      this.destinationAgents = await this.$store.dispatch('company/keyWordLookUp', new CompanyKeyWordLookUpRequest(query, this.order.destinationAgentId, '01'));
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.destinationAgents = [];
    }
  }

  async loadConsignees(query) {
    if (query !== null && query.length >= 1) {
      this.isLoading = true;
      this.consignees = await this.$store.dispatch('company/keyWordLookUp', new CompanyKeyWordLookUpRequest(query, this.order.consigneeId, '02'));
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.consignees = [];
    }
  }

  async loadOrigin(query) {
    if (query !== null && query.length >= 1) {
      this.isLoading = true;
      this.originLocations = await this.$store.dispatch('tradetransportlocation/keyWordLookUp', new KeyWordLookUpRequest(query, this.order.originId));
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.originLocations = [];
    }
  }

  supplierClear() {
    (this.$refs.loadingAddress as any).clearSingleSelect();
    this.loadingAddresses = [];
  }

  consigneeClear() {
    (this.$refs.unloadingAddress as any).clearSingleSelect();
    this.unloadingAddresses = [];
  }

  async isSupplierChange(val: number) {
    if (this.isNumber(val)) {
      (this.$refs.loadingAddress as any).clearSingleSelect();
      if (val) {
        await this.LoadLoadingAddresses(val)
      }
    }
  }

  async isConsigneeChange(val) {
    if (this.isNumber(val)) {
      (this.$refs.unloadingAddress as any).clearSingleSelect();
      if (val) {
        await this.LoadUnloadingAddresses(val)
      }
    }
  }

  isNumber(value: string | number): boolean {
    return ((value != null) &&
        (value !== '') &&
        !isNaN(Number(value.toString())));
  }

  async loadDestination(query) {
    if (query !== null && query.length >= 3) {
      this.isLoading = true;
      this.destinationLocations = await this.$store.dispatch('tradetransportlocation/keyWordLookUp', new KeyWordLookUpRequest(query, this.order.destinationId));
      this.isLoading = false;
    } else {
      this.isLoading = false;
      this.destinationLocations = [];
    }
  }

  async LoadLoadingAddresses(companyId: number) {
    if (companyId) {
      this.loadingAddresses = await this.$store.dispatch('address/lookUp', companyId);
    } else {
      this.loadingAddresses = [];
    }
  }

  async LoadUnloadingAddresses(companyId: number) {
    if (companyId) {
      this.unloadingAddresses = await this.$store.dispatch('address/unloadingLookUp', companyId);
    } else {
      this.unloadingAddresses = [];
    }
  }

  async mounted() {
    this.incoterms = await this.$store.dispatch('incoterm/lookUp');
    this.shippingMethods = await this.$store.dispatch('order/shippingMethods');
    this.statuses = await this.$store.dispatch('order/shippingStatuses');
    await this.getDocuments();
  }

  async getDocuments() {
    let id = parseInt(this.$route.params["orderId"]);
    this.documents = await this.$store.dispatch('order/getDocuments', id);
    console.log(this.documents)
  }

  save() {
    (this.$refs.orderForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.isSaveButtonDisabled = true;
        try {
          await this.$store.dispatch({
            type: this.toEdit ? 'order/updateForAdmin' : 'order/createForAdmin',
            data: this.order
          });
        } catch {
          this.isSaveButtonDisabled = false;
          return;
        }
        (this.$refs.orderForm as any).resetFields();
        this.$emit('save-success');
        this.$emit('input', false);
        this.$router.back();
      }
    })
  }

  cancel() {
    (this.$refs.orderForm as any).resetFields();
    this.$emit('input', false);
    this.$router.back();
  }

  async created() {
    let id = parseInt(this.$route.params["orderId"]);
    if (id !== -1) {
      this.$store.commit('order/editAdminShippingOrder', await this.$store.dispatch({
        type: 'order/getAdmin',
        data: id
      }));
    }
    this.order = Util.extend(true, {}, this.$store.state.order.editAdminShippingOrder);
    this.toShowCustomSupplier = this.order.supplierId === null && this.order.customSupplierName !== null;
    this.toShowCustomConsignee = this.order.consigneeId === null && this.order.customConsigneeName !== null;
    if (this.order.id) {
      this.toEdit = true;
      await this.LoadLoadingAddresses(this.order.supplierId);
      await this.LoadUnloadingAddresses(this.order.consigneeId)
    }
  }

  private showCustomSupplierOrConsigneeRow() {
    return this.toShowCustomSupplier || this.toShowCustomConsignee
  }

  checkClientInput(rule, value, callback) {
    if (value == null) {
      return callback(new Error(this.L('FieldIsRequired', undefined, this.L('Client'))));
    }
    return callback();
  }

  adminOrderRule = {
    clientId: [{
      required: true,
      validator: this.checkClientInput,
      trigger: 'change'
    }]
  }
}
